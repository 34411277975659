<script setup>
	const { item } = defineProps({
		item: {
			type: Object,
			required: true,
		},
		attributes: {
			type: Object,
			default: () => ({}),
		},
	});

	const imageSrc = computed(() => {
		if (item?.image) return item?.image;
		if (item?.src) return item;
	});
</script>
<template>
	<div class="slide fragment">
		<!-- This was throwing an error because item.image was an object not URL -->
		<MessImage v-if="imageSrc" :image="imageSrc" :alt="item?.alt || ''" />
		<div v-if="item?.caption" class="caption-container">
			<p v-html="item.caption"></p>
		</div>
	</div>
</template>

<style lang="scss">
	.slide {
		.caption-container {
			padding: 25px 30px;
			background-color: var(--gray-100);
		}
	}
</style>
